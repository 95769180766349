require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
import Rails from '@rails/ujs';
global.Rails = Rails;

import "styles/application.scss"
import 'semantic-ui-sass'

import './kitchencoders'
require.context('../images', true)
