import Glide from "@glidejs/glide"
import imagesLoaded from 'imagesloaded'
import Masonry from 'masonry-layout'
# Prism.highlightAll()
 

class Paralax
  constructor: (id)->
    @container = $("##{id}")
    @layers = []

    @add_layer = (layer)->
      @layers.push layer

    @container.on 'mousemove', (e)=>
      center =
        top: $(window).height() / 2
        left: $(window).width() / 2

      $.each @layers, (index, layer)->
        layer.move e.pageY, e.pageX, center

class Layer
  constructor: (e_class, h_speed, v_speed)->
    @element = $(".layer.#{e_class}")
    @h_speed = h_speed
    @v_speed = v_speed

    @move = (mouse_x, mouse_y, center)=>
      @element.css
        top: ((center.top - mouse_x) * @v_speed * 0.1)
        left: ((center.left - mouse_y) * @h_speed * 0.1)

cloudloop = ->
  clouds = $('#clouds')
  clouds.css
    left: -2500
  clouds.animate
    left: '2500'
    15000, ->
      cloudloop ->

# map
$ ->
  if $('#map').length > 0
    ymaps.ready ->
      map = new ymaps.Map 'map',
        center: [59.93730512, 30.32853516]
        zoom: 16
        controls: ['zoomControl']
        behaviors: ['drag']

      pm = new ymaps.Placemark [59.937355,30.328070], {}, {
        balloonOffset: [0,-65]
        hideIconOnBalloonOpen: false
        iconLayout: 'default#image'
        iconImageHref: '/pin.png'
        iconImageSize: [125, 97]
        iconImageOffset: [-55, -85]
      }
      map.geoObjects.add pm

$ ->

  # paralax slider
  paralax_sky = new Paralax 'paralax-container-sky'
  paralax_see = new Paralax 'paralax-container-see'

  layer_1 = new Layer 'sky', 1, 0.4
  layer_2 = new Layer 'mountines_1', 1.5, 0.8
  layer_3 = new Layer 'mountines_2', 2, 1
  layer_4 = new Layer 'ground', 2.5, 1.5

  layer_5 = new Layer 'sky1', 1.7, 0
  layer_6 = new Layer 'sun_haze', 0.2, 0.5
  layer_7 = new Layer 'wave1', -1.5, 0.1
  layer_8 = new Layer 'wave2', 0.1, 0.3
  layer_9 = new Layer 'wave3', 2.5, 0

  paralax_sky.add_layer layer_1
  paralax_sky.add_layer layer_2
  paralax_sky.add_layer layer_3
  paralax_sky.add_layer layer_4
  paralax_see.add_layer layer_5
  paralax_see.add_layer layer_6
  paralax_see.add_layer layer_7
  paralax_see.add_layer layer_8
  paralax_see.add_layer layer_9

  # clouds loop
  cloudloop ->

  # border
  container = $('html, body')
  line_after = $('.line-after')
  line_before = $('.line-before')
  vertical_border = $('.vertical-border')

  line_after.css {
    position: "absolute"
    height: 2
    background: "black"
    top: -7
    left: "50%"
    marginLeft: 106
    width: (container.width() - 106*2 - 6*2) / 2
  }

  line_before.css {
    position: "absolute"
    height: 2
    background: "black"
    top: -7
    left: -7
    marginRight: 106
    width: (container.width() - 106*2 - 6*2) / 2
  }

  $(window).on 'resize', (e)->
    line_before.css {
      width: (container.width() - 106*2 - 6*2) / 2
    }

    line_after.css {
      width: (container.width() - 106*2 - 6*2) / 2
    }

  $(document).on 'scroll', (e)->
    vertical_border.css {
      marginTop: $(document).scrollTop() + 6
    }

  # Logo optimization
  logo = $('header.main .logo')
  logo_small = $('header.main .logo-small')
  lines = $('.line-before, .line-after')
  hidden = false
  offset = 50
  top_border = $('.top-border')
  screen_width = $(window).width()

  if screen_width > 1000

    $(document).on 'scroll', (e)->
      if $(document).scrollTop() > offset && !hidden
        logo.fadeOut(150)
        logo_small.fadeIn(150)
        lines.hide()
        top_border.show()
        hidden = true

      else if $(document).scrollTop() <= offset && hidden
        logo.fadeIn(150)
        logo_small.fadeOut(150)
        lines.show()
        top_border.hide()
        hidden = false

# Animated objects
$ ->
  if $('canvas#rocket').length > 0
    canvas = document.getElementById("rocket")
    canvas.width = 55
    canvas.height = 158
    context = canvas.getContext('2d')

    image = new Image
    image.src = "/images/rocket_animate.png"

    frames_order = [0,1,2,1,0]
    frame = 1
    fps = 15

    draw = ->
      context.clearRect(0, 0, 55, 158)
      context.drawImage image, 0, 158 * (frames_order[frame % 5]) , 55, 158, 0, 0, 55, 158
      frame += 1
      # loop
      setTimeout ()->
          requestAnimationFrame(draw)
        , 1000 / fps

    image.onload = ()->
      draw()

$ ->
  if $('canvas#boat').length > 0
    canvas = document.getElementById("boat")
    canvas.width = 177
    canvas.height = 196
    context = canvas.getContext('2d')

    image = new Image
    image.src = "/images/boat_animate.png"

    frames_order = [0,1,2,3,4,3,1,0]
    frame = 1
    fps = 5

    draw = ()->
      context.clearRect(0, 0, 177, 196)
      context.drawImage image, 0, 196 * (frames_order[frame % 5]) , 177, 196, 0, 0, 177, 196
      frame += 1
      # loop
      setTimeout ()->
          requestAnimationFrame(draw)
        , 1000 / fps

    image.onload = ()->
      draw()

$ ->
  $('a[href*="#"]:not([href="#"])').click ->
    if location.pathname.replace(/^\//, '') == @pathname.replace(/^\//, '') and location.hostname == @hostname
      target = $(@hash)
      target = if target.length then target else $('[name=' + @hash.slice(1) + ']')
      if target.length
        $('html, body').animate { scrollTop: target.offset().top - 87 }, 1000
        $('.ui.sidebar').sidebar('toggle');
        return false

    return

  return

$ ->

  $('.mainmenu').click ->
    $('.ui.sidebar').sidebar('toggle');


$(document).ready ->

  if $('.glide').length
    glide = new Glide '.glide',
      autoplay: 5000
      hoverpause: false

    glide.mount()

  if $('.grids').length
    grid = document.querySelector('.grids')
    msnry = new Masonry(grid,
      itemSelector: 'none'
      columnWidth: '.grid-sizer'
      gutter: 15
      stagger: 10
      percentPosition: true
      visibleStyle:
        transform: 'translateY(0)'
        opacity: 1
      hiddenStyle:
        transform: 'translateY(100px)'
        opacity: 0)
    imagesLoaded grid, ->
      grid.classList.remove 'are-images-unloaded'
      msnry.options.itemSelector = '.grid-item'
      items = grid.querySelectorAll('.grid-item')
      msnry.appended items
      return
